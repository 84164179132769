<template>
  <svg width="24" height="24" viewBox="3 3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 16.9999H19M5 11.9999H19M5 6.99994H19"
      stroke="#333333"
      stroke-width="1.37143"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts" setup></script>
